@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

.highlight {
    font-weight: bolder;
    background: linear-gradient(
            104deg,
            rgba(255, 229, 0, 0) 0.9%,
            #ffee3c 2.4%,
            rgba(255, 229, 0, 0.73) 5.8%,
            rgba(255, 229, 0, 0.22) 93%,
            rgba(255, 229, 0, 0.7) 96%,
            rgba(255, 229, 0, 0) 98%
        ),
        linear-gradient(183deg, rgba(255, 229, 0, 0), rgba(255, 229, 0, 0.3) 7.9%, rgba(255, 229, 0, 0) 15%);
    padding: 0.12em 13.7px;
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px #fff98c, 21px -18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}

.highlight-minimal {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #fff98c 50%);
}

.legal {
    font-family: Inter var, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.legal h3 {
    font-size: 2.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: -0.025em;
    color: #161e2e;
    font-weight: 800;
}

.legal h4 {
    font-size: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: -0.025em;
    color: #161e2e;
    font-weight: 700;
}

.legal h5 {
    font-size: 1.75rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: -0.025em;
    color: #161e2e;
    font-weight: 700;
}

.legal ul {
    list-style-type: disc;
    list-style-position: inside;
    color: #6b7280;
    margin-top: 1rem;
    font-size: 0.95rem;
}

.legal ol {
    list-style-type: decimal;
    list-style-position: inside;
    color: #6b7280;
    margin-top: 1rem;
    font-size: 0.95rem;
}

.legal p {
    color: #6b7280;
    margin-top: 1rem;
    font-size: 0.95rem;
}

.noUi-horizontal {
    height: 10px !important;
}

.noUi-connect {
    background: #5850eb !important;
}

.noUi-horizontal .noUi-handle {
    width: 15px !important;
    height: 20px !important;
    right: -8px !important;
    top: -6px !important;
}

.noUi-handle:before, .noUi-handle:after {
    /*content: "";*/
    display: block;
    position: absolute;
    height: 0 !important;
    width: 0 !important;
    background: #E8E7E6;
    left: 14px;
    top: 6px;
}

.domainpage-datepicker {
    font-size: 0.875rem;
}

.domainpage-datepicker .react-date-picker__wrapper {
    border: none !important;
    /*padding: 0.5rem 0.75rem;*/
}

.domainpage-datepicker .react-calendar {
    border-radius: 0.375rem !important;
    border-width: 1px;
    border-color: #d2d6dc;
}

.domainpage-datepicker input[type=number] {
    padding: 0 2px;
    background: inherit;
}

.domainpage-datepicker .react-calendar__month-view__days__day--weekend {
    color: inherit;
}
.domainpage-datepicker .react-calendar__tile--active {
    color: white;
}
